




























































































































































































































import moment from 'moment'
import Component from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Api, InvoiceFilter, PagedResults, SalesSourceDepts, SalesSourcePersons, StripeInvoiceInfo, SubscriptionPlanInfo, TableState } from '@/edshed-common/api'

import { Mixins } from 'vue-property-decorator'
import InvoicePlans from './components/InvoicePlans.vue'

@Component({
  name: 'InvoicesView',
  components: { InvoicePlans }
})

export default class Invoices extends Mixins(ComponentHelper) {
  loading: boolean = false

  invoicesData: PagedResults<StripeInvoiceInfo> = { // cannot set PagedResults<StripeInvoiceInfo> (readonly)
    items: [],
    total: 0
  }

  table: TableState = {
    page: 1,
    perPage: 25,
    sort: 'id',
    dir: 'desc',
    term: ''
  }

  filters: InvoiceFilter = {
    edshed: true,
    litshed: true,
    uk: true,
    us: true
  }

  persons = SalesSourcePersons

  depts = SalesSourceDepts

  response: string | null = null

  plans: SubscriptionPlanInfo[] = []

  mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      this.getInvoices()
      this.getPlans()
    }
  }

  async getPlans () {
    try {
      this.plans = await Api.getSubscriptionPlans({}, undefined)
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load plans',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async getInvoices () {
    this.loading = true
    try {
      this.invoicesData = await Api.getInvoices({ skip: (this.table.page - 1) * this.table.perPage, take: this.table.perPage, sort: this.table.sort, dir: this.table.dir, term: this.table.term }, this.filters)
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Could not load invoices',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }

  async saveSourcePerson (obj: StripeInvoiceInfo) {
    try {
      const id = obj.id
      if (!obj.source_person) {
        throw new Error('No person to save!')
      }
      const person = obj.source_person

      await Api.setInvoiceSourcePerson(id, person)
      this.$buefy.toast.open({
        message: 'Source person saved successfully!',
        type: 'is-success',
        position: 'is-bottom',
        duration: 5000
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not save the source person',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 5000
      })
    }
  }

  async saveSourceDept (obj: StripeInvoiceInfo) {
    try {
      const id = obj.id
      if (!obj.source_dept) {
        throw new Error('No dept to save!')
      }
      const dept = obj.source_dept

      await Api.setInvoiceSourceDept(id, dept)
      this.$buefy.toast.open({
        message: 'Source dept saved successfully!',
        type: 'is-success',
        position: 'is-bottom',
        duration: 5000
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not save the source dept',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 5000
      })
    }
  }

  onPageChange (page: number) {
    this.table.page = page
    this.getInvoices()
  }

  formattedDate (d) {
    return moment(d).format('DD/MM/YYYY')
  }
}
