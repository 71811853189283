

















import { StripeInvoiceInfo, SubscriptionPlanInfo } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  name: 'InvoicePlans'
})
export default class InvoicePlans extends Mixins(ComponentHelper) {
  @Prop({ required: true }) invoice!: StripeInvoiceInfo

  @Prop({ required: true }) plans!: SubscriptionPlanInfo[]

  get planDetails () {
    return this.plans.filter(p => this.invoice.items.find(i => i.plan_id === p.id))
  }

  get basePlanDetails () {
    return this.planDetails.find(p => p.parent_id === null)
  }

  get addOnPlans () {
    const basePlan = this.basePlanDetails

    if (!basePlan) {
      return []
    }

    return this.planDetails.filter(p => p.id !== basePlan.id)
  }

  get shouldShowLitshedTag () {
    return this.basePlanDetails?.product.product_type === 'litshed'
  }

  get shouldShowEdshedTag () {
    return this.basePlanDetails && this.basePlanDetails.product.product_type !== 'litshed'
  }

  get shouldShowSpellingTag () {
    return this.basePlanDetails?.product.product_type === 'spelling' || this.addOnPlans.find(p => p.product.product_type === 'spelling')
  }

  get shouldShowNumberTag () {
    return this.basePlanDetails?.product.product_type === 'number' || this.addOnPlans.find(p => p.product.product_type === 'number')
  }

  get shouldShowPhonicsTag () {
    return (this.basePlanDetails?.product.product_type === 'phonics' || this.addOnPlans.find(p => p.product.product_type === 'phonics')) ||
    (this.basePlanDetails?.product.product_type === 'phonics_digital' || this.addOnPlans.find(p => p.product.product_type === 'phonics_digital'))
  }

  get shouldShowClassesTag () {
    return this.basePlanDetails?.metered_entity === 'class'
  }

  get shouldShowTeachersTag () {
    return this.basePlanDetails?.metered_entity === 'teacher'
  }

  get shouldShowPupilsTag () {
    return this.basePlanDetails?.metered_entity === 'pupil'
  }

  get basePlanInvoiceItem () {
    return this.invoice.items.find(i => i.plan_id === this.basePlanDetails?.id)
  }
}
